const Refer = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33148 2.3657H5.51054C5.95669 2.3657 6.31836 2.72737 6.31836 3.17352V3.62321H5.33148C4.98422 3.62321 4.70272 3.34171 4.70272 2.99446C4.70272 2.6472 4.98422 2.3657 5.33148 2.3657ZM5.51054 1.2038C6.59839 1.2038 7.48027 2.08567 7.48027 3.17352V3.62321V3.64795H7.48387V3.62321V3.17352C7.48387 2.08567 8.36575 1.2038 9.4536 1.2038H9.63266C10.6216 1.2038 11.4233 2.0055 11.4233 2.99446C11.4233 3.22507 11.3797 3.4455 11.3003 3.64795H12.3628C13.1872 3.64795 13.8555 4.31626 13.8555 5.14065V5.73207C13.8555 6.449 13.35 7.04788 12.6761 7.19184V12.4982C12.6761 13.3226 12.0078 13.9909 11.1834 13.9909H3.78482C2.96042 13.9909 2.29212 13.3226 2.29212 12.4982V7.19172C1.61843 7.04756 1.11328 6.44881 1.11328 5.73207V5.14065C1.11328 4.31626 1.78159 3.64795 2.60598 3.64795H3.66381C3.58441 3.4455 3.54082 3.22507 3.54082 2.99446C3.54082 2.0055 4.34252 1.2038 5.33148 1.2038H5.51054ZM6.91734 4.80985H2.60598C2.42329 4.80985 2.27518 4.95796 2.27518 5.14065V5.73207C2.27518 5.91477 2.42329 6.06287 2.60598 6.06287H2.86386C2.86692 6.06282 2.86999 6.0628 2.87307 6.0628H6.91734V4.80985ZM12.1043 6.06287H12.3628C12.5455 6.06287 12.6936 5.91477 12.6936 5.73207V5.14065C12.6936 4.95796 12.5455 4.80985 12.3628 4.80985H8.07924V6.0628H12.0951C12.0982 6.0628 12.1013 6.06282 12.1043 6.06287ZM3.45402 7.22477V12.4982C3.45402 12.6809 3.60212 12.829 3.78482 12.829H6.91734L6.91734 7.22477H3.45402ZM8.07924 7.22477L8.07924 12.829H11.1834C11.3661 12.829 11.5142 12.6809 11.5142 12.4982V7.22477H8.07924ZM9.4536 2.3657H9.63266C9.97992 2.3657 10.2614 2.6472 10.2614 2.99446C10.2614 3.34171 9.97992 3.62321 9.63266 3.62321H8.64578V3.17352C8.64578 2.72737 9.00745 2.3657 9.4536 2.3657Z"
        fill="#333333"
      />
    </svg>
  )
}

export default Refer
